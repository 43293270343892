<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\bookingsettings\\edit.9872') }}</v-toolbar-title>
            </v-toolbar>
            <v-select
                v-model="entity.trip"
                :label="$t('src\\views\\bookingsettings\\edit.263')"
                :items="tripsSelect"
                item-text="name"
                item-value="_id"
                outlined
            />
            <v-select
                v-model="entity.directionType"
                :label="$t('src\\views\\bookingsettings\\edit.7326')"
                :items="directionTypesSelect"
                item-text="title"
                item-value="value"
                outlined
            />
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.approveCount"
                    :label="$t('src\\views\\bookingsettings\\edit.9664')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.approveDays"
                    :label="$t('src\\views\\bookingsettings\\edit.4312')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.prepaymentPercent"
                    :label="$t('src\\views\\bookingsettings\\edit.6722')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.prepaymentDays"
                    :label="$t('src\\views\\bookingsettings\\edit.1180')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.cancelFullDays"
                    :label="$t('src\\views\\bookingsettings\\edit.8210')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.cancelHalfDays"
                    :label="$t('src\\views\\bookingsettings\\edit.2427')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.endOfBookingDays"
                    :label="$t('src\\views\\bookingsettings\\edit.6694')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\bookingsettings\\edit.8387') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\bookingsettings\\edit.2338') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, translit } from '../../helpers';
    import { DIRECTION_TYPE, DIRECTION_TYPE_TITLE } from '../../vars';

    export default {
        name: 'NewsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            DIRECTION_TYPE,
            DIRECTION_TYPE_TITLE,
            imageSrc,
            valid: true,
        }),
        computed: {
            ...mapState('bookingSettings', ['entity']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            tripsSelect() {
                return [
                    { _id: null, name: 'По умолчанию' },
                    ...this.trips
                ]
            },
            directionTypesSelect() {
                const directionTypes = [];
                for(const item in DIRECTION_TYPE) {
                    directionTypes.push({
                        value: DIRECTION_TYPE[item],
                        title: this.$t(DIRECTION_TYPE_TITLE[DIRECTION_TYPE[item]])
                    })
                }
                return directionTypes;
            }
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('bookingSettings/get', { id: this.$route.params.id });
            } else {
                await store.commit('bookingSettings/CLEAR_ENTITY');
            }
            await store.dispatch('trips/fetch', { itemsPerPage: 100000 });
        },
        methods: {
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('bookingSettings/SET_ENTITY', this.entity);
                    const response = await store.dispatch('bookingSettings/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'booking-settings-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('bookingSettings/delete', { id: this.entity._id });
                await this.$router.push({ name: 'booking-settings-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .bookingSettings__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
